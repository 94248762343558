import {
  FaBriefcase,
  FaBuilding,
  FaCalculator,
  FaDollarSign,
  FaListAlt,
  FaMagic,
  FaNewspaper,
} from "react-icons/fa";
import { Services as ServicesData } from "../data/services";
import { useSmoothScrollTo } from "../utility/utils";
import { Box } from "./box";

const iconMapper: { [key: string]: any } = {
  FaNewspaper: FaNewspaper,
  FaCalculator: FaCalculator,
  FaMagic: FaMagic,
  FaBriefcase: FaBriefcase,
  FaListAlt: FaListAlt,
  FaBuilding: FaBuilding,
  FaDollarSign: FaDollarSign,
} as const;

export const Services = () => {
  const bind = useSmoothScrollTo("#Service");
  return (
    <div {...bind}>
      <div className="container px-6 py-8 mx-auto">
        <h3 className="text-yellow-500 text-3xl md:text-4xl lg:text-5xl py-8 md:py-12 text-center">
          Our Services
        </h3>

        <div className="grid grid-cols-1 gap-8 mt-6 xl:mt-12 xl:gap-12 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {ServicesData.map((data) => (
            <div
              className="w-full p-8 space-y-8 text-center border border-gray-200 rounded-lg flex  flex-col"
              key={data.title}
            >
              <span className="text-5xl font-bold text-gray-800 uppercase flex justify-center">
                {data.icon ? <Box as={iconMapper[data.icon]} /> : null}
              </span>
              <h3 className="font-base">{data.title}</h3>
              <p className="font-sm text-gray-400 flex">{data.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
