export const Services: { title: string; icon: string; description: string }[] =
  [
    {
      title: "TAX RETURNS",
      icon: "FaNewspaper",
      description:
        "With years of experience and a team of knowledgeable tax professionals, we are here to simplify the tax filing process for you. Whether you're an individual or a small business owner, we strive to ensure accurate and efficient tax returns, helping you navigate the complexities of tax laws and maximize your refunds. Say goodbye to tax worries and let us handle your taxes with precision and care.",
    },
    {
      title: "ACCOUNTING",
      icon: "FaCalculator",
      description:
        "Unlock the power of numbers with our trusted accounting services. We provide accurate financial insights and expert guidance to help your business thrive. From bookkeeping to tax preparation, our dedicated team is here to streamline your financial processes and drive your success forward. Let us be your trusted partner in maximizing profitability and achieving your financial goals.",
    },
    {
      title: "COMPANY & TRUST FORMATIONS",
      icon: "FaBuilding",
      description:
        "Start your business journey with confidence through our expert Company & Trust Formations services. Our tax accountants are here to guide you through the process, offering tailored solutions to meet your specific needs. From company registration to trust setup, we ensure compliance and help you lay a solid foundation for success.",
    },
    {
      title: "SMSF",
      icon: "FaDollarSign",
      description:
        "Secure your financial future with our SMSF (Self-Managed Superannuation Fund) tax accountant services. We specialize in helping individuals and businesses establish and manage SMSFs, providing expert guidance and compliance support. Trust us to optimize your retirement savings and navigate the complex SMSF regulations with confidence.",
    },
    {
      title: "BOOKKEEPING",
      icon: "FaListAlt",
      description:
        "Simplify your financial management with our professional bookkeeping and tax accounting services. We handle your day-to-day financial tasks, ensuring accurate record-keeping and timely tax filings. Trust us to keep your books in order and maximize your tax savings, giving you peace of mind to focus on growing your business.",
    },
    {
      title: "ADVISORY",
      icon: "FaCalculator",
      description:
        "Tax guidance, accounting process advice, financial analysis and informed decision making, long and short term strategy, structuring advice, and tax planning are some of the services we provide.",
    },

        {
      title: "AUDIT & ASSURANCE",
      icon: "FaMagic",
      description:
        "With our team of skilled accountants and auditors, we specialize in providing thorough and reliable assessments of financial records to ensure accuracy and compliance. Whether you need financial statement audits, internal control evaluations, or risk assessments, we are committed to delivering high-quality results that instill confidence in your business operations.",
    },
    {
      title: "BUSINESS PLANS",
      icon: "FaBriefcase",
      description:
        "Achieve your business goals with our expert Business Plans services. Our tax accountants assist in developing comprehensive business plans tailored to your specific needs, helping you secure funding, make informed decisions, and drive growth. Trust us to provide strategic insights and financial expertise to turn your vision into a successful reality.",
    },
  ];
