import {
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";
import { Contacts as contactData } from "../data/contact";
export const Social = () => {
  const hrefContact = `tel: ${contactData.phone}`;

  return (
    <nav className="bg-gray-400 text-white shadow sticky top-0">
      <div className="container px-6 py-2 mx-auto hidden md:flex">
        <div className="flex flex-row-reverse md:flex-row md:justify-between">
          <div className="items-center hidden md:flex">
            <h2 className="title-font font-semibold tracking-widest text-xl ">
              Contact us :
            </h2>

            <a className="text-xl " href={hrefContact}>
              {contactData.phone}
            </a>
          </div>

          {/* <div className="items-center md:flex right-0">
            <div className="flex  gap-4 flex-row md:mx-6">
              <FaFacebook className="social" />
              <FaInstagram className="social" />
              <FaTwitter className="social" />
              <FaPinterest className="social" />
            </div>
          </div> */}
        </div>
      </div>
    </nav>
  );
};
