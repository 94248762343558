import { useState } from "react";
import { FaBars } from "react-icons/fa";

export const Header = () => {
  const [selectedMenu, setSelectedMenu] = useState<number>(1);
  const [isMobileOpen, setMobileOpen] = useState<Boolean>(false);
  return (
    <nav className="bg-white shadow  sticky top-0 z-20">
      <div className="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
        <div className="flex items-center justify-between">
          <div>
            <a
              className="text-2xl text-yellow-500 font-bold transition-colors duration-200 transform  lg:text-3xl hover:text-yellow-700 "
              href="#Home"
            >
              Taxzone
            </a>
          </div>

          {/* Mobile menu button */}
          <div className="flex md:hidden">
            <button
              type="button"
              className="text-gray-500  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
              aria-label="toggle menu"
              onClick={() => setMobileOpen(!isMobileOpen)}
            >
              <FaBars />
            </button>
          </div>
        </div>

        {/* Mobile Menu open: "block", Menu closed: "hidden"  */}
        <div className={`items-center md:flex ${isMobileOpen ? "" : "hidden"}`}>
          <div className="flex flex-col md:flex-row md:mx-6 gap-4">
            <a
              className=" my-1 text-sm font-medium text-gray-700 transition-colors duration-200 transform  hover:text-blue-500  md:mx-4 md:my-0"
              href="#Home"
              onClick={() => {
                setSelectedMenu(1);
                setMobileOpen(false);
              }}
            >
              <span
                className={`${
                  selectedMenu === 1 ? "border-b-2 border-yellow-500 pb-2" : " "
                }`}
              >
                Home
              </span>
            </a>
            <a
              className="my-1 text-sm font-medium text-gray-700 transition-colors duration-200 transform  hover:text-blue-500 md:mx-4 md:my-0"
              href="#Service"
              onClick={() => {
                setSelectedMenu(2);
                setMobileOpen(false);
              }}
            >
              <span
                className={`${
                  selectedMenu === 2 ? "border-b-2 border-yellow-500 pb-2" : " "
                }`}
              >
                Services
              </span>
            </a>
            <a
              className="my-1 text-sm font-medium text-gray-700 transition-colors duration-200 transform hover:text-blue-500 md:mx-4 md:my-0"
              href="#Contact-us"
              onClick={() => {
                setSelectedMenu(3);
                setMobileOpen(false);
              }}
            >
              <span
                className={`${
                  selectedMenu === 3 ? "border-b-2 border-yellow-500 pb-2" : " "
                }`}
              >
                Contact Us
              </span>
            </a>
            {/* <a
              className="my-1 text-sm font-medium text-gray-700 transition-colors duration-200 transform hover:text-blue-500 md:mx-4 md:my-0"
              href="#About-us"
              onClick={() => {
                setSelectedMenu(4);
                setMobileOpen(false);
              }}
            >
              <span
                className={`${
                  selectedMenu === 4 ? "border-b-2 border-yellow-500 pb-2" : " "
                }`}
              >
                About Us
              </span>
            </a> */}
          </div>
        </div>
      </div>
    </nav>
  );
};
