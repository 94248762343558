import { useEffect, useRef } from "react";

export const useSmoothScrollTo = (id: string) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const listener = (e: any) => {
      if (ref.current && window.location.hash === id) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };
    window.addEventListener("hashchange", listener, true);
    return () => {
      window.removeEventListener("hashchange", listener);
    };
  }, [id]);
  return {
    "data-anchor-id": id,
    ref,
  };
};
