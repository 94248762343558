import { useSmoothScrollTo } from "../utility/utils";
export const Hero = () => {
  const bind = useSmoothScrollTo("#Home");
  return (
    <div {...bind} className="mx-auto text-white relative">
      <div className="banner"></div>
      {/* <img src={masterImage} alt="masterImage" /> */}
      <div className="text-slate-200 absolute w-full px-8 z-10 top-1/4 text-center left-2/4 -translate-x-2/4">
        <div className="mb-4 lg:text-8xl md:text-7xl text-6xl">Taxzone</div>
        <div className="mb-4 lg:text-6xl md:text-5xl text-3xl">
          Quality Accounting Services
        </div>
        <p className="text-1xl md:text-2xl">
          We offer services and insights that empower businesses and individuals
          to reach their goals
        </p>
      </div>
    </div>
  );
};
