import { ContactUs } from "./components/contact-us";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { Hero } from "./components/hero";
import { Services } from "./components/services";
import { Social } from "./components/social";

function App() {
  return (
    <div className=" w-full ">
      <Social />
      <Header />
      <Hero />
      <Services />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
