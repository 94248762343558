import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

export type AlertProp = {
  alertType: "success" | "error";
};
export const Alert = ({ alertType }: AlertProp) => {
  return (
    <div className="flex w-full max-w-sm mx-auto overflow-hidden bg-gray-200 rounded-lg shadow-md fixed bottom-8 right-8">
      <div className="flex items-center justify-center w-12 bg-emerald-500">
        {alertType === "success" ? <FaCheckCircle /> : <FaTimesCircle />}
      </div>

      <div className="px-4 py-2 -mx-3">
        <div className="mx-3">
          <span className="font-semibold text-emerald-500 capitalize">
            {alertType}
          </span>
          <p className="text-sm text-gray-600 ">
            {alertType === "success" ? "Your query submitted!" : "Some error!"}
          </p>
        </div>
      </div>
    </div>
  );
};
