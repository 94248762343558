import { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";

export type BoxProps<T extends ElementType> = {
  as?: T;
  children?: ReactNode;
};

export const Box = <T extends ElementType = "div">({
  as,
  ...props
}: BoxProps<T> & ComponentPropsWithoutRef<T>) => {
  const Component = as || "div";
  return <Component {...props} />;
};
