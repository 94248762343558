export const Contacts: {
  email: string;
  phone: string;
  address: string;
  iFrameAddress: string;
  title: string;
  info: string;
} = {
  email: "info@taxzone.com.au",
  phone: "0432 231 412",
  address: "279 The Boulevarde, Fairfield Heights NSW 2165",
  iFrameAddress:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13252.280748182384!2d150.938768!3d-33.862082!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b1297c460e2d3c1%3A0x8857e9131282ee9d!2sTAX%20ZONE!5e0!3m2!1sen!2sau!4v1688274088893!5m2!1sen!2sau",
  title: "Get in Touch",
  info: "You can contact us any way that is convenient for you. You can also use a quick contact form below or visit our office personally. We would be happy to answer your questions.",
};
